// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';

// Components
import PublicEvent from './PublicEvent';
import Loader from '../shared/Loader';

// Actions
import eventActions from '../../actions/eventActions';

// Selectors
import { getAllEvents, getCityConfig } from '../../selectors';

// Misc
import EventUtils from '../../modules/eventUtils';
import styles from './PublicEventsCalendar.module.postcss';
import eventStyles from './PublicEvents.module.postcss';

class PublicEventsCalendar extends Component {
  constructor(props) {
    super(props);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.onCalendarNavigate = this.onCalendarNavigate.bind(this);
    this.unselectEvent = this.unselectEvent.bind(this);

    moment.tz.setDefault(moment.tz.guess());

    this.state = {
      page: 1,
      ready: false,
      selectedEvent: null,
      date: moment().toDate()
    };
  }

  componentDidMount() {
    this.props.clearEvents().then(() => {
      const startDate = moment().startOf('month').subtract(7, 'days').format('M-D-YY');
      const endDate = moment().endOf('month').add(7, 'days').format('M-D-YY');
      this.props.fetchEvents({
        page: 1,
        page_size: 300,
        start_date: startDate,
        end_date: endDate
      });

      this.setState({
        ready: true
      });
    });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.page !== this.state.page) {
      this.props.fetchEvents({ page: nextState.page, page_size: 300 });
    }

    if (!this.state.selectedEvent) return;

    const selectedEventId = this.state.selectedEvent.event.id;
    const selectedEventStamp = this.state.selectedEvent.event.updated_at;

    const nextEvent = _.find(nextProps.events, { id: selectedEventId });
    if (nextEvent && nextEvent.updated_at !== selectedEventStamp) {
      this.setState({
        selectedEvent: {
          event: nextEvent,
          x: this.state.selectedEvent.x,
          y: this.state.selectedEvent.y
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.pod !== prevProps.pod ||
        this.props.location !== prevProps.location ||
        this.props.truck !== prevProps.truck) {
      this.props.fetchEvents({ page: this.state.page, page_size: 300 });
    }
  }

  onCalendarNavigate(date, view) {
    const startDate = moment(date).startOf('month').subtract(7, 'days').format('M-D-YY');
    const endDate = moment(date).endOf('month').add(7, 'days').format('M-D-YY');
    this.props.fetchEvents({
      page: this.state.page,
      page_size: 300,
      start_date: startDate,
      end_date: endDate
    });
    this.setState({
      date
    });
  }

  onSelectEvent(event, e) {
    const selectedEvent = {
      event: event.originalEvent
    };

    this.setState({
      selectedEvent
    });
  }

  unselectEvent() {
    this.setState({ selectedEvent: null });
  }

  render() {
    const { events, truck, location, showLocations, pod, showTrucks, disableLoader, admin, cityConfig } = this.props;
    const { selectedEvent, ready, date } = this.state;
    const localizer = momentLocalizer(moment);

    if (!ready) return null;

    let eventsForCalendar = null;

    if (events) {
      eventsForCalendar = events;

      eventsForCalendar = eventsForCalendar.map((event) => {
        return {
          title: event.display_name,
          start: moment(event.start_time).toDate(),
          end: moment(event.end_time).toDate(),
          originalEvent: event
        };
      });
    }

    return (
      <Loader entities="event" actions="fetch" disable={disableLoader}>
        {eventsForCalendar &&
          <div className={styles.PublicCalendar}>
            <Calendar
              events={eventsForCalendar}
              localizer={localizer}
              views={['month']}
              popup
              date={date}
              onSelectEvent={this.onSelectEvent}
              onNavigate={this.onCalendarNavigate}
            />
            {selectedEvent &&
              <div
                className={eventStyles.Popup_Event}
                onClick={this.unselectEvent}
              >
                <PublicEvent
                  event={selectedEvent.event}
                  truck={truck}
                  cityConfig={cityConfig}
                  showLocations={showLocations}
                  showTrucks={showTrucks}
                  admin={admin}
                />
              </div>
            }
          </div>
        }
      </Loader>
    );
  }
}

PublicEventsCalendar.propTypes = {
  clearEvents: PropTypes.func,
  fetchEvents: PropTypes.func,
  events: PropTypes.array,
  truck: PropTypes.string,
  location: PropTypes.number,
  showTrucks: PropTypes.bool,
  pod: PropTypes.string,
  includeLocations: PropTypes.bool,
  showLocations: PropTypes.bool,
  includeBookings: PropTypes.bool,
  disableLoader: PropTypes.bool,
};

function mapStateToProps(state, props) {
  return {
    events: getAllEvents(state, props),
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    fetchEvents(localOptions) {
      const options = EventUtils.prepOptions(localOptions, props);
      dispatch(eventActions.fetch(options));
    },
    clearEvents() {
      return dispatch(eventActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicEventsCalendar);
