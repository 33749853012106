// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import cn from 'classnames';
import _ from 'lodash';

// Components
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

// Selectors
import { getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';

import styles from './PublicEvents.module.postcss';

class PublicEvent extends Component {
  constructor(props) {
    moment.tz.setDefault('America/Los_Angeles');
    super(props);
  }

  isChecked(bookingId) {
    const { checkedBookings } = this.props;
    return _.includes(checkedBookings, bookingId.toString());
  }

  getMetaName() {
    const { event, includeMeta, cityConfig } = this.props;

    if (event.bookings && event.bookings.length > 0) {
      let trucks = _.map(event.bookings, _.iteratee('truck'));
      let truck_names = _.map(trucks, _.iteratee('name'));
      return truck_names.join(', ');
    } else {
      return `${includeMeta.name} Food ${cityConfig.vehicle_type}s`;
    }
  }

  render() {
    const { event, showTrucks, showLocations, includeMeta, cityConfig } = this.props;

    let location = null;
    if (event.location && showLocations) {
      location = (
        <Link to={`/schedule/${event.location.id}`}>
          {event.location.name &&
            <div className={styles.Event_locationName}>
              {event.location.name}
            </div>
          }
          <div className={styles.Event_locationAddress}>
            <span className="icon icon-pin" />
            {event.location ? event.location.filtered_address : event.name}
          </div>
        </Link>
      );
    } else if (event.name) {
      location = (
        <div className="u-strong">
          {event.name}
        </div>
      );
    }

    let meta = null;
    if (includeMeta) {
      let name = '';
      let locationName = '';
      let address = '';
      if (includeMeta.type === 'truck' && event.location) {
        locationName = event.location.name ? event.location.name : event.location.filtered_address;
        address = event.location.address;
        name = `${name} Food ${cityConfig.vehicle_type}s`;
      } else { // type === 'location'
        name = this.getMetaName();
        locationName = includeMeta.name;
        address = includeMeta.address;
      }

      const metaJSON = {
        "@context": "http://schema.org",
        "@type": "FoodEvent",
        "name": name,
        "description": includeMeta.description,
        "startDate": moment(event.start_time).format(),
        "endDate": moment(event.end_time).format(),
        "url": `${includeMeta.url}#${event.id}`,
        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
        "eventStatus": "https://schema.org/EventScheduled",
        "image": includeMeta.image,
        "location": {
          "@type": "Place",
          "name": locationName,
          "address": address
        }
      }
      meta = (
        <script type="application/ld+json">
          {JSON.stringify(metaJSON)}
        </script>
      );
    }

    let bookings = null;

    if (event.bookings && event.bookings.length > 0) {
      bookings = event.bookings.map((booking) => {
        const t = booking.truck;

        let image = null;
        if (t.featured_photo) {
          image = `${IsoMorphUtils.railsContext.imageBase}/${t.featured_photo}`;
        } else {
          image = '/static/images/truck-placeholder.png';
        }

        const categories = t.food_categories.join(', ');
        const status = booking.status;

        if (status !== 'approved') {
          return null;
        }

        return (
          <div
            className={cn({
              [`${styles.Event_truck} Booking--${status}`]: true,
              [styles.Event_truck__checked]: (this.isChecked(booking.id))
            })}
            key={booking.id}
          >
            <Link
              className={styles.Event_truckLink}
              to={`/food-trucks/${t.id}`}
            >
              <div
                className={styles.Event_truckImage}
                style={{ backgroundImage: `url("${image}")` }}
              />

              <div className={styles.Event_truckText}>
                <div className={styles.Event_truckName}>
                  {t.name}
                </div>
                {categories.length > 0 &&
                  <span className={styles.Event_truckCategories}>
                    {categories}
                  </span>
                }
              </div>
            </Link>
          </div>
        );
      });
    }

    bookings = _.reject(bookings, (booking) => {
      return _.isUndefined(booking) || _.isNull(booking);
    });

    if (bookings.length === 0) {
      bookings = (
        <div className={styles.Event_noTrucks}>
          No {cityConfig.vehicle_type}s Booked
        </div>
      );
    }

    return (
      <div className={styles.Event + " u-mb1"}>
        <div className={styles.Event_title}>
          <div className={styles.Event_time}>
            <div className={styles.Event_timeDay}>
              <span className="icon icon-calendar" />
              {moment(event.start_time).format('dddd, MMMM Do')}
            </div>

            <div className={styles.Event_timeHours + " u-noMobile"}>
              <span className="icon icon-clock" />
              {moment(event.start_time).format('h:mma')}-
              {moment(event.end_time).format('h:mma')}
            </div>
          </div>
        </div>

        <div className={styles.Event_timeHours + " u-mobileOnly"}>
          <span className="icon icon-clock" />
          {moment(event.start_time).format('h:mma')}-
          {moment(event.end_time).format('h:mma')}
        </div>

        {location &&
          <div className={styles.Event_header}>
            <div className={styles.Event_location}>
              {location}
            </div>
          </div>
        }

        {!location && showLocations &&
          <div className={styles.Event_header}>
            <div className={styles.Event_location}>
              <div className={styles.Event_locationName}>
                {event.title}
              </div>
            </div>
          </div>
        }

        {bookings && showTrucks &&
          <div>
            {bookings}
          </div>
        }

        <Helmet>
          {meta}
        </Helmet>
      </div>
    );
  }
}

PublicEvent.propTypes = {
  event: PropTypes.object,
  truck: PropTypes.string,
  pod: PropTypes.string,
  locations: PropTypes.string,
  showTrucks: PropTypes.bool,
  showLocations: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout() {
      dispatch(userLogout());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicEvent);
